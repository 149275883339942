import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

function Status(props) {
  const [online, setOnline] = useState(true);

  useEffect(() => {
    document.addEventListener(`server_status_${props.id}`, e => {
      setOnline(e.detail.online)
    })
  })

  return (
    <p className={`status ${online ? 'online' : 'offline'}`}>
      <span></span>
      { online ? 'online' : 'offline' }
    </p>
  )
}

Status.propTypes = {
  id: PropTypes.number
}

export default Status
