import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

function Load(props) {
  const [load, setLoad] = useState("no_load");

  useEffect(() => {
    document.addEventListener(`server_status_${props.id}`, e => {
      const loadPercent = e.detail.load
      let loadValue = load;

      if(typeof loadPercent === "number"){
        if(loadPercent < 33){
          loadValue = "low"
        }else if(loadPercent < 66){
          loadValue = "medium"
        }else{
          loadValue = "high"
        }
      }

      setLoad(loadValue)
    })
  }, [props.id, setLoad])

  return (
    <p className={`status ${load}`}>
      <span />
      {I18n.t(`server_load.${load}`)}
    </p>
  )
}

Load.propTypes = {
  id: PropTypes.number
}

export default Load
